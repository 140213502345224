import cn from 'classnames'
import styles from './styles.module.css'
import { useState } from 'react'
// import { Icons } from '../index'
// import { hexToRgba } from '../../utils'
import Icons  from '../../icons'
import { FaHotel, FaPlus } from 'react-icons/fa';
import { GoHome } from 'react-icons/go';
import { IoIosAnalytics } from 'react-icons/io';
import { FcServices } from 'react-icons/fc';
import { BsSun, BsSunFill } from 'react-icons/bs';


const CheckboxButtonBox = ({
  onChange,
  className,
  color,
  value = false,
  name,
  id
}) => {
  const clickHandler = () => {
    onChange && onChange(id)
  }
  const classNames = cn(styles.checkbox, className, {
    [styles['checkbox_active']]: value
  })

  return <div className={styles['checkbox-container ']}>
   
    <button
      className={'box'}
      onClick={clickHandler}
      style={{ backgroundColor: value && color }}
      type='button'
    >
       <FcServices />
     <span className={value ? 'has-text-white ml-2' : 'ml-2'}>{name}</span>
     <span className='ml-2'>{value ? <BsSunFill/> : <BsSun/>}</span>
     <p className='content is-small mt-2'>{value ? 'выбрана' : 'не выбрана'}</p>
    </button>
    
    
  </div>
}


export default CheckboxButtonBox;