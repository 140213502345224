import React, { useContext } from 'react';
import { Container, Main, Button, TagsContainer, LinkComponent, Icons } from '../../components';
import { UserContext, AuthContext } from '../../contexts';
import styles from './styles.module.css';
import Description from './description';
import { useParams, useHistory, Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { useRecipe, useRecipeData } from '../../utils';
import { MdContactPhone } from 'react-icons/md';
import GalleryWidgetDZ from '../../components/GalleryWidgetDZ';
import useSWR, { mutate } from 'swr';
import api from '../../api';

const SingleCard = ({ loadItem, updateOrders }) => {
  const { id } = useParams();
  const history = useHistory();
  // Check if `id` is defined before making the API request
  const fetchRecipe = id ? () => api.getRecipe({ recipe_id: id }) : null;

  // Update the `useSWR` hook to handle the case when `fetchRecipe` is `null`
  const { data: recipeData, error: recipeError, mutate: revalidateRecipe } = useSWR(fetchRecipe ? `/api/recipes/${id}` : null, fetchRecipe, {
    shouldRetryOnError: false,
  });

  const { recipe, setRecipe, handleLike, handleAddToCart, handleSubscribe } = useRecipe();
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);

  if (recipeError) {
    history.push('/recipes');
  }

  if (!recipeData) {
    return <>Loading......</>; // Add a loading indicator here
  }

  const {
    author = {},
    image,
    tags,
    cooking_time,
    name,
    tower,
    text,
    is_favorited,
    is_in_shopping_cart,
    images_recipe,
  } = recipeData;

  const handleLikeClick = async () => {
    const toLike = !is_favorited;
    await handleLike({ id, toLike });
    revalidateRecipe();
  };

  const handleAddToCartClick = async () => {
    const toAdd = !is_in_shopping_cart;
    await handleAddToCart({ id, toAdd, callback: updateOrders });
    revalidateRecipe();
  };

  const handleSubscribeClick = async () => {
    const toSubscribe = !author?.is_subscribed;
    await handleSubscribe({ author_id: author?.id, toSubscribe });
    revalidateRecipe();
  };

  return (
    <Main>
      <Container>
        <MetaTags>
          <title>{name}</title>
          <meta name="description" content={`Помощник по покупке/продаже - ${name}`} />
          <meta property="og:title" content={name} />
        </MetaTags>
        <div className={styles['single-card']}>
          <div className={styles['single-card__image-container']}>
            <img src={image} alt={name} className={styles['single-card__image']} />
          </div>

          {images_recipe && images_recipe.length > 0 && (
            <GalleryWidgetDZ images_list={images_recipe?.map(({ imageUrl }) => imageUrl)} />
          )}
          <p className={styles['h2Custom']}>
            <span>
              <Icons.ClockIcon />
              Цена {cooking_time} руб.
            </span>
          </p>

          <div className={styles['single-card__info']}>
            <div className={styles['single-card__header-info']}>
              <h1 className={styles['single-card__title']}>{name}</h1>
              {authContext && (
                <Button modifier="style_none" clickHandler={handleLikeClick}>
                  {is_favorited ? <Icons.StarBigActiveIcon /> : <Icons.StarBigIcon />}
                  {recipeData.isLikeLoading ? '...' : ''}
                </Button>
              )}
            </div>
            <TagsContainer tags={tags} />
            <div>
              <div className={styles['single-card__text']}>
                <Icons.UserIcon />{' '}
                <LinkComponent
                  title={`${author?.first_name} ${author?.last_name}`}
                  href={`/user/${author?.id}`}
                  className={styles['single-card__link']}
                />
              </div>
              {(userContext || {}).id === author?.id && (
                <LinkComponent
                  href={`/recipes/${id}/edit`}
                  title="Редактировать объект"
                  className={styles['single-card__edit']}
                />
              )}
            </div>
            <div className={styles['single-card__buttons']}>
              {authContext && (
                <Button
                  className={styles['single-card__button']}
                  modifier={is_in_shopping_cart ? 'style_light' : 'style_dark-blue'}
                  clickHandler={handleAddToCartClick}
                >
                  {is_in_shopping_cart ? (
                    <>
                      <Icons.DoneIcon color="#4A61DD" />
                      {recipeData.isAddToCartLoading ? '...' : 'Объект добавлен'}
                    </>
                  ) : (
                    <>
                      <Icons.PlusIcon /> {recipeData.isAddToCartLoading ? '...' : 'Добавить в покупки'}
                    </>
                  )}
                </Button>
              )}
              {(userContext || {}).id !== author?.id && authContext && (
                <Button
                  className={styles['single-card__button']}
                  modifier="style_light-blue"
                  clickHandler={handleSubscribeClick}
                >
                  {author?.is_subscribed ? 'Отписаться от автора' : 'Подписаться на автора'}
                  {recipeData.isSubscribeLoading ? '...' : ''}
                </Button>
              )}
              <p className="tag mt-2">{tower}</p>
            </div>
            <Description description={text} />
            <a href={`tel:${author?.phone_user}`}>
              <button className="button has-background-warning is-medium mb-3">
                <span className="icon is-small">
                  <MdContactPhone />
                </span>
                <span>Позвонить</span>
              </button>
            </a>
          </div>
        </div>
      </Container>
    </Main>
  );
};

export default SingleCard;
