import React, { useState } from "react";
import useSWR, { mutate } from 'swr';
import api from '../api';

export default function useRecipe() {
  const [recipe, setRecipe] = useState({});

  const { data: recipeData, error: recipeError } = useSWR(recipe?.id ? `/api/recipes/${recipe.id}` : null, () =>
    api.getRecipe({ recipe_id: recipe.id })
  );

  const handleLike = async ({ id, toLike = 1 }) => {
    try {
      const method = toLike ? api.addToFavorites.bind(api) : api.removeFromFavorites.bind(api);
      await method({ id });
      const recipeUpdated = { ...recipeData, is_favorited: Number(toLike) };
      setRecipe(recipeUpdated);
      mutate(`/api/recipes/${recipe.id}`); // Trigger revalidation
    } catch (err) {
      const { errors } = err;
      if (errors) {
        alert(errors);
      }
    }
  };

  const handleAddToCart = async ({ id, toAdd = 1, callback }) => {
    try {
      const method = toAdd ? api.addToOrders.bind(api) : api.removeFromOrders.bind(api);
      await method({ id });
      const recipeUpdated = { ...recipeData, is_in_shopping_cart: Number(toAdd) };
      setRecipe(recipeUpdated);
      callback && callback(toAdd);
      mutate(`/api/recipes/${recipe.id}`); // Trigger revalidation
    } catch (err) {
      const { errors } = err;
      if (errors) {
        alert(errors);
      }
    }
  };

  const handleSubscribe = async ({ author_id, toSubscribe = 1 }) => {
    try {
      const method = toSubscribe ? api.subscribe.bind(api) : api.deleteSubscriptions.bind(api);
      await method({ author_id });
      const recipeUpdated = {
        ...recipeData,
        author: { ...recipeData.author, is_subscribed: toSubscribe }
      };
      setRecipe(recipeUpdated);
      mutate(`/api/recipes/${recipe.id}`); // Trigger revalidation
    } catch (err) {
      const { errors } = err;
      if (errors) {
        alert(errors);
      }
    }
  };

  return {
    recipe: recipeData,
    setRecipe,
    handleLike,
    handleAddToCart,
    handleSubscribe
  };
}
