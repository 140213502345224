
import React, { useState, useEffect } from "react";
import { Container, FileInput, Input, Title, CheckboxGroup, Main, Form, Button, Checkbox, Textarea } from '../../components';
import { useTags } from '../../utils';
import MetaTags from 'react-meta-tags';
import { fetcherUtils } from "../../utils/fetchUtils";
import { useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useSWRConfig } from "swr";
import api from "../../api";
import useSWR, { mutate } from "swr";
import { FaUpload } from 'react-icons/fa';
import styles from './styles.module.css';


const RecipeCreate = ({ onEdit }) => {
  const [recipeName, setRecipeName] = useState("");
  const history = useHistory();
  const [recipeText, setRecipeText] = useState("");
  const [recipeTime, setRecipeTime] = useState("");
  const [recipeTower, setRecipeTower] = useState("");
  const [recipeImages, setRecipeImages] = useState([]);
  const [recipeFile, setRecipeFile] = useState(null);
  const [isCreatingRecipe, setIsCreatingRecipe] = useState(false); // Indicator for recipe creation



 

  const { data: dataTags } = useSWR("/tags", () => api.getTags());
  const {
    value: valueTags,
    handleChange: handleChangeTags,
    setValue: setValueTags,
  } = useTags();

  useEffect(() => {
    setValueTags(dataTags?.map((tag) => ({ ...tag, value: true })));
  }, [dataTags]);

  const handleDrop = (acceptedFiles) => {
    const images = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setRecipeImages(images);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: handleDrop,
  });

  const handleRemoveImage = (e,index) => {
    e.preventDefault();
    const updatedImages = [...recipeImages];
    updatedImages.splice(index, 1);
    setRecipeImages(updatedImages);
  };

  const convertImageUrlToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const base64 = await convertBlobToBase64(blob);
      return base64;
    } catch (error) {
      console.error("Error converting image to base64:", error);
      return null;
    }
  };

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const createRecipe = async (e) => {
    e.preventDefault();
    const base64Images = await Promise.all(
      recipeImages.map(async (image) => {
        if (image.preview.startsWith("http" || "https")) {
          const base64 = await convertImageUrlToBase64(image.preview);
          return base64;
        } else {
          const response = await fetch(image.preview);
          const blob = await response.blob();
          const base64 = await convertBlobToBase64(blob);
          return base64;
        }
      })
    );

    const imagesData = recipeImages.map((image, index) => ({
      errors: "",
      valid: true,
      name: image.name,
      size: image.size,
      type: image.type,
      imageUrl: base64Images[index],
      lastModified: image.lastModified,
    }));

    const data = {
      name: recipeName,
      text: recipeText,
      time: recipeTime,
      tower: recipeTower,
      cooking_time: recipeTime,
      image: recipeFile,
      tags: valueTags.filter((item) => item.value).map((item) => item.id),
      images_recipe: imagesData,
    };
    console.log("Data", data);

    try {
      setIsCreatingRecipe(true); // Set the indicator to true before making the request
      const token = localStorage.getItem("token");
      await fetcherUtils(`${process.env.REACT_APP_API_URL_SERVER}/api/recipes/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token}`,
        },
        body: JSON.stringify(data),
      });
    
      // Redirect to the desired page
      mutate("/recipes/"); // Mutate the recipe list data
      history.push("/recipes/"); // Redirect to recipe list page
    } catch (error) {
      console.error("Error creating recipe:", error);
      // Handle recipe creation error
    } finally {
      setIsCreatingRecipe(false); // Reset the indicator after the request is completed
    }
    };

  const checkIfDisabled = () => {
    return (
      recipeText === "" ||
      recipeName === "" ||
      valueTags.filter((item) => item.value).length === 0 ||
      recipeTime === "" ||
      recipeFile === "" ||
      recipeTower === "" ||
      recipeFile === null
    );
  };

  return (
    <Main>
      <Container>
        <MetaTags>
          <title>Создание объекта</title>
          <meta name="description" content="Помощник по покупке/продаже - Создание объекта" />
          <meta property="og:title" content="Создание объекта" />
        </MetaTags>
        <Title title='Создание объекта' />
        <Form onSubmit={(e) => createRecipe(e)}>
          <Input
            label='Название объекта'
            onChange={(e) => {
              const value = e.target.value;
              setRecipeName(value);
            }}
          />
          <Input
            label='Город объекта'
            onChange={(e) => {
              const value = e.target.value;
              setRecipeTower(value);
            }}
          />
          <CheckboxGroup
            label='Теги'
            values={valueTags}
            className={`${styles.checkboxGroup}`}
            labelClassName={styles.checkboxGroupLabel}
            tagsClassName={styles.checkboxGroupTags}
            checkboxClassName={styles.checkboxGroupItem}
            handleChange={handleChangeTags}
          />

          <div className={styles.cookingTime}>
            <Input
              label='Стоимость объекта'
              labelClassName={styles.cookingTimeLabel}
              onChange={(e) => {
                const value = e.target.value;
                setRecipeTime(value);
              }}
              value={recipeTime}
            />
            <div className={styles.cookingTimeUnit}>руб.</div>
          </div>
          <Textarea
            label='Описание объекта'
            onChange={(e) => {
              const value = e.target.value;
              setRecipeText(value);
            }}
          />

          <FileInput
            onChange={(file) => {
              setRecipeFile(file);
            }}
            className={styles.fileInput}
            label='Загрузить фото'
          />

          {/* Dropzone */}
          <div className={`box`} {...getRootProps()}>
            <input {...getInputProps()} />
            <p><FaUpload color="green"/> Перетащите изображения сюда или кликните для выбора файлов</p>
          </div>
          {recipeImages.length > 0 && (
            <div>
              {recipeImages.map((image, index) => (
                <div key={index}>
                  <img src={image.preview} alt={`Image ${index}`} />
                  <button onClick={(e) => handleRemoveImage(e,index)}>
                    Удалить
                  </button>
                </div>
              ))}
            </div>
          )}
          <Button
            modifier='style_dark-blue'
            disabled={checkIfDisabled()}
            className={styles.button}
          >
             {isCreatingRecipe ? 'Создание объекта...' : 'Создать объект'}
          </Button>
        </Form>
      </Container>
    </Main>
  );
};

export default RecipeCreate;
