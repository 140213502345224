import { useRecipes } from '../../utils/index.js'
import { useEffect, useState } from 'react'
import api from '../../api'
import CategoryDacha from '../../components/CategoryDacha'
import CategoryHotelPage from '../TempCategory/CategoryHotelPage'

const HotelPage = ({ updateOrders, orders }) => {
  const {
    recipes,
    setRecipes,
    handleAddToCart
  } = useRecipes()
  
  const getRecipes = () => {
    api
      .getRecipes({
        page: 1,
        limit: 999,
        is_in_shopping_cart: Number(true)
      })
      .then(res => {
        const { results } = res
        setRecipes(results)
      })
  }

  useEffect(_ => {
    getRecipes()
  }, [])

 
  return <section className="hero">
  <div className="hero-body pt-1">
    <p className="title level-left">
    Отель
    </p>
    <CategoryDacha />
  </div>
  <CategoryHotelPage />
</section>

}

export default HotelPage;
