import logoMoney from './images/money.png';

const MoneyIcon = (props) => {
  return (
  
      <img src={logoMoney} width={22} height={21} {...props} alt="fireMoney" />   
 
  );
}

export default MoneyIcon