
import React, { useState } from 'react'
import styles from './styles.module.css'
import { GiHamburgerMenu } from 'react-icons/gi';
import Logo from '../logo';
import {Nav, AccountMenu } from '../index.js'
import SocialNetworking from '../SocialNetworkingList';
const BulmaMenuMobile = ({loggedIn, onSignOut, orders}) => {
    const [isShown, setIsShown] = useState(false);
  

    return ( <>
<nav className="level is-mobile navbar is-fixed-top ml-4 mr-4 ">
  <div className="level-item-left has-text-centered">
    <div className="navbar-brand">
        <a href="/recipes">
            <Logo />  
        </a>  
    </div>
  </div>
  
  <div className="level-item-right has-text-centered">
    <div className="dropdown is-right is-hoverable">
        <div className="dropdown-trigger">
            <button className="button" aria-haspopup="true"
                aria-controls="dropdown-menu4">
                <span className="icon is-small">
                    <GiHamburgerMenu />
                    <i className="fas fa-angle-down" aria-hidden="true"></i>
                </span>
            </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu4" role="menu">
            <div className="dropdown-content">
                <Nav loggedIn={loggedIn} orders={orders} />
                <div className="navbar-end">
                  <div className="navbar-item">
                    <div className="buttons">                   
                      <AccountMenu onSignOut={onSignOut} /> 
                    </div>
                  </div>
                </div>
                <nav className="has-text-left-mobile">
                    <div className='level-left'>
                        <SocialNetworking />
                    </div>
                </nav>
            </div>
        </div>
    </div>
  </div>
</nav>   
    </> );
}
 
export default BulmaMenuMobile;