import { Container, Input, Title, Main, Form, Button } from '../../components'
import styles from './styles.module.css'
import { useFormWithValidation } from '../../utils'
import { AuthContext } from '../../contexts'
import { useContext } from 'react'
import MetaTags from 'react-meta-tags'
import React, { useState } from 'react'


const PageForgotChangePassword = ( props ) => {
const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation()
const authContext = useContext(AuthContext)

const [data_response, setDates] = useState([])

  return <Main>
    <Container>
      <MetaTags>
        <title>Изменить пароль</title>
        <meta name="description" content="Помощник по покупке/продаже - Изменить пароль" />
        <meta property="og:title" content="Изменить пароль" />
      </MetaTags>
      <Title title='Изменить пароль' />
      <Form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault()

          try {
            fetch('/api/users/reset_password_confirm/',{
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    "uid": props.match.params.uid,
                    "token": props.match.params.token,
                    "new_password": values.new_password
                  })
                
            }).then(
              response => response.json() )
              .then((result) => { 
                console.log("callback", result);
                setDates(result);
 
                if (data_response.new_password && data_response.new_password.length > 0) {
                   alert(data_response.new_password?.map(item => item));
                }else {
                  alert ("Ваш пароль изменен")
                }                           
                                   
            })
          } catch (err) {     
            console.log('error fetch email', err);
          }
        }
      }>

        <Input
          required
          label='Новый пароль'
          type='password'
          name='new_password'
          onChange={handleChange}
        />
         
        <Input
          required
          label='Подтверждение нового пароля'
          type='password'
          name='repeat_password'
          onChange={handleChange}
        />
        
        <Button
          modifier='style_dark-blue'
          type='submit'
          disabled={!isValid || values.new_password !== values.repeat_password}
        >
          Изменить пароль
        </Button>       
      </Form>
    </Container>
  </Main>
}

export default PageForgotChangePassword;
