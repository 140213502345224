
import React from 'react'


class ForgotForm extends React.Component {
        constructor(props) {
          super(props);
          this.state = {
            value: '',
            response_value: '',
            date: new Date(),

            };
          this.handleChange = this.handleChange.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
        }
        
      
        handleChange(event) { this.setState({value: event.target.value}); }



        handleSubmit(event) {
            event.preventDefault();
         
       console.log(this.state.response_value.status);
       
        
        try {
             fetch('/api/users/custom_forgot_password_reset_email/',{
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({"email": this.state.value})
                
            }).then(res => res.json() )
              .then((result) => { 
                console.log("callback", result);
                this.setState({response_value: result});   
                if (this.state.response_value['status']  && true) {
                  alert('Проверьте электронную почту: ' + this.state.value + ', отправлена ссылка для восстановления');
                }
                else {
                  alert('Попробуйте снова, возникла ошибка или почта: '+ this.state.value +' не зарегистрирована');
                }
                
            })
          
          } catch (err) {
          
            console.log('error fetch email', err);
          
          }

        }

      render() {
        return (
          <nav className='level' >
          <section className='level-item' >
        <form onSubmit={this.handleSubmit}> 
          <div className='box has-background-warning mb-3' style={{width: "450px"}}>      
           <label>
           Введите электронную почту для сброса пароля:
                <input 
                className='input mb-3'
               
                type='email'
                name='email'
                value={this.state.value} onChange={this.handleChange} />       
            </label>




            <input 
              className='button'
              disabled={!this.state.value}
              type="submit" value="Сбросить" />
            </div>
          </form>

     


          </section>
          </nav>
        );
      
  }
    }

    export default ForgotForm;
