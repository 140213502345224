import React, { Component } from 'react';
import imageLogo from './images/logo_main.png';

class Logo extends Component {
  render() {
    return (
      <div >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
  <path d="M12 1L2 8v13a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V8L12 1zm7 19H5V9h14v11z"/>
  <path d="M11 16h2v2h-2zm6-10h-3V4h-2v2H9V4H7v2H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1zm-1 12H5V9h12v9z"/>
</svg>
      </div>
    )
  }
}

export default Logo