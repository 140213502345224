import React, { useState } from "react";
import { FaHotel, FaPlus } from 'react-icons/fa';
import styles from './style.module.css'

import { GoHome } from 'react-icons/go';
import { IoIosAnalytics } from 'react-icons/io';

import { FcServices } from 'react-icons/fc';

const CategoryDacha = () => {

  
  return <>
<section >
<div className="tabs is-centered ">
  <ul >
    <li className={`${styles.customCategory} ${(window.location.pathname === "/country-house") ? "is-active" : ""}`}>
      <a href="/country-house?tags=dachi-0">
        <span className="icon is-small">
        <GoHome />
            
            </span>
        <span className={`box  ${(window.location.pathname === "/country-house") ? "box has-background-warning" : ""}`}>Дача</span>
      </a>
    </li>
    <li  className={`${styles.customCategory} ${(window.location.pathname === "/hotel-page") ? "is-active" : ""}`}>
      <a href="/hotel-page">
        <span className="icon is-small"><FaHotel /></span>
        <span className={` ${(window.location.pathname === "/hotel-page") ? "box has-background-warning" : "box"}`}>Отель</span>
      </a>
    </li>
    <li className={`ml-2 ${styles.customCategory} ${(window.location.pathname === "/sanatorium-page") ? "is-active" : ""}`} >
      <a href="/sanatorium-page">
        <span className="icon is-small"><IoIosAnalytics /></span>
        <span className={`box ${(window.location.pathname === "/sanatorium-page") ? "box has-background-warning" : ""}`}>Санаторий</span>
      </a>
    </li>
    <li className={`ml-2 ${styles.customCategory} ${(window.location.pathname === "/services-page") ? "is-active" : ""}`}>
      <a href="/services-page">
        <span className="icon is-small"><FcServices /></span>
        <span className={`box ${(window.location.pathname === "/services-page") ? "box has-background-warning" : ""}`}>Услуги</span>
      </a>
    </li>
  </ul>
</div>

</section>
  </>
}

export default CategoryDacha;