import { GoHome } from 'react-icons/go';
import { FcPlus } from 'react-icons/fc';
import { RiAccountPinBoxLine } from 'react-icons/ri';
import { FaTelegramPlane } from 'react-icons/fa';
import SocialNetworking from '../SocialNetworkingList';
const BottnMenuAppMobile = ( {loggedIn}) => {
   
    return ( <>
    
    <nav className="navbar has-background-grey-light pb-0 mb-0 is-fixed-bottom" role="navigation">
    <footer className="card-footer">
        <a href='/recipes' className="card-footer-item ">
            
            <p className="is-size-5 "><GoHome /></p>
        </a>
        <a href={'/recipes/create'} className="card-footer-item ">
            
            <p className="is-size-5"><FcPlus /></p>
        </a>
        <a href='/change-password' className="card-footer-item">
            
            <p className="is-size-5"><RiAccountPinBoxLine /></p>
        </a>
                   
<SocialNetworking />
        
    </footer>
</nav>
    </> );
}
 
export default BottnMenuAppMobile;