import React from "react";


export function useImages() {
  const [images, setImages] = React.useState([]);

  const imageArray = []; // Перемещено сюда

  const getBase64 = (file) => {
    const reader = new FileReader();

    if (file.file) {
      reader.readAsDataURL(file.file);
    }
    reader.onload = function () {
      imageArray.push({
        errors: "",
        valid: file.valid,
        name: file.file.name,
        size: file.file.size,
        type: file.file.type,
        imageUrl: reader.result,
        lastModified: new Date(),
      });
      setImages(imageArray);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const convertBase64 = () => {
    images.forEach((image) => {
      getBase64(image);
    });
  };

  return { images, convertBase64, setImages };
}
