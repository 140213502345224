import logoHeardsmallFree from './images/logoHeardsmallFree.png';

const StarIcon = (props) => {
  return (
  
      <img src={logoHeardsmallFree} width={22} height={21} {...props} alt="fireHeard" />   
 
  );
}

export default StarIcon