import {
  Card,
  Title,
  Pagination,
  CardList,
  Container,
  Main,
  CheckboxGroup,
} from "../../components";
import styles from "./styles.module.css";
import { useRecipes } from "../../utils/index.js";
import { useEffect } from "react";
import api from "../../api";
import MetaTags from "react-meta-tags";
// import Modal from '../../components/modal/Modal'
// import ModalDashboard from '../../components/modal-dashboard/ModalDashboard'
import { useState } from "react";
import "bulma/css/bulma.min.css";
// corousel
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "react-device-detect";
import CardGallary from "../../components/card-gallary";

import Modal1 from "../../components/modal-1";
import CategoryDacha from "../../components/CategoryDacha";
import CheckboxGroupButtomCustom from "../../components/CategoryGroup/checkbox-group-buttom-custom";
import LoadingListComponent from "../../components/loading-list-component";
import useSWR from "swr";


const HomePage = ({ updateOrders }) => {



  
  const [isTOP, setIsTOP] = useState(false);

  const [valueTower, setValueTower] = useState("");

  const ChangeTowerSelect = (event) => {
    setValueTower(event.target.value);
  };

  const settingsdESCTOP = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 3000,
    centerPadding: "5px",
  };

  const settingsMobile = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    centerPadding: "2px",
  };

  const [show, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const {
    // recipes,
    // setRecipes,
    recipesCount,
    setRecipesCount,
    recipesPage,
    setRecipesPage,
    tagsValue,

    setTagsValue,
    handleTagsChange,
    handleLike,
    handleAddToCart,
  } = useRecipes();

  const { data: recipesData, mutate: setRecipes, isLoading :isRecipesDataLoading } = useSWR(
    ["recipes", recipesPage, tagsValue, valueTower, isTOP],
    (key, recipesPage, tagsValue, valueTower, isTOP) =>
      api.getRecipes({ page: recipesPage, tags: tagsValue, tower: valueTower, is_in_top: isTOP }),
    { revalidateOnFocus: false }
  );

  const towers_list = [...new Set(recipesData?.results?.map(({ tower }) => `${tower}`))];
  const listOptionTower = towers_list?.map((name_tower) => (
    <option key={name_tower}>{name_tower}</option>
  ));

  const getRecipes = ({ page = 1, tags, tower, is_in_top }) => {
    api.getRecipes({ page, tags, tower, is_in_top }).then((res) => {
      const { results, count } = res;
      // console.log("RES",res)
      setRecipes(results);
      setRecipesCount(count);
    });
  };

  useEffect(
    () => {
      getRecipes({
        page: recipesPage,
        tags: tagsValue,
        tower: valueTower,
        is_in_top: isTOP,
      });
    },
    [recipesPage, tagsValue, valueTower, isTOP]
  );
  // console.log("tagsValue", tagsValue);

  useEffect((_) => {
    api.getTags().then((tags) => {
      setTagsValue(tags?.map((tag) => ({ ...tag, value: true })));
    });
  }, []);

  



  return (
    <Main>
      <Container>
        <MetaTags>
          <title>Объекты</title>
          <meta name="description" content="Помощник по покупке/продаже - Объекты" />
          <meta property="og:title" content="Объекты" />
        </MetaTags>


        {isRecipesDataLoading && <LoadingListComponent  />}

        {isMobile ? <section className="hero is-large mt-6"></section> : ""}

        <section className="">
          <p className="title ">Категории</p>
          <div className="tabs">
            <CheckboxGroupButtomCustom
              values={tagsValue}
              handleChange={handleTagsChange}
            />
          </div>
        </section>

        {recipesData?.results?.length > 1 && <section className="hero ">
          <p className="title mt-2 mb-0 "> Галерея </p>
          <div className="hero-body pl-0 pr-0 pt-2">
            {isMobile ? (
              <Slider {...settingsMobile}>
                {recipesData?.results?.map((card) => (
                  <CardGallary
                    {...card}
                    key={card.id}
                    updateOrders={updateOrders}
                    handleLike={handleLike}
                    handleAddToCart={handleAddToCart}
                  />
                ))}
              </Slider>
            ) : (
              <Slider {...settingsdESCTOP}>
                {recipesData?.results?.map((card) => (
                  <CardGallary
                    {...card}
                    key={card?.id}
                    updateOrders={updateOrders}
                    handleLike={handleLike}
                    handleAddToCart={handleAddToCart}
                  />
                ))}
              </Slider>
            )}
          </div>
        </section>}

        {/* Modal1 */}

        <section>
          <Modal1 show={show} handleClose={handleClose}>
            <div className="box modal-content">
              <CheckboxGroup
                values={tagsValue}
                handleChange={handleTagsChange}
              />
            </div>
          </Modal1>
        </section>
        {/* end Modal1 */}

        {/* corousel */}

        {recipesData?.results?.length > 0 && <div className={styles.title}>
          <Title title="Объекты" />

          {/* menu left */}
          <div className="tabs mb-2 ">
            <ul>
              {/* tower list */}
              <div className="select mr-2 is-rounded is-small">
                <select value={valueTower} onChange={ChangeTowerSelect}>
                  <option value={""}>Все города</option>
                  {listOptionTower}
                </select>
              </div>
              {/* end tower list */}
              <button
                className="button is-rounded is-small mr-2"
                onClick={() => setIsTOP(!isTOP)}
              >
                {isTOP ? "ВСЕ" : "TOP"}
              </button>
              <button
                className="button is-rounded is-small"
                onClick={handleOpen}
              >
                # Фильтр
              </button>
            </ul>

            {/*  */}
          </div>
          {/*  */}
          {/* end menu left */}

        </div>}

        <CardList>
          {recipesData?.results?.map((card) => (
            <Card
              {...card}
              key={card?.id}
              updateOrders={updateOrders}
              handleLike={handleLike}
              handleAddToCart={handleAddToCart}
            />
          ))}
        </CardList>
        <Pagination
          count={recipesData?.count}
          limit={6}
          page={recipesPage}
          onPageChange={(page) => setRecipesPage(page)}
        />
      </Container>
    </Main>
  );
};

export default HomePage;
