import React, { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import useSWR from "swr";
import { useDropzone } from "react-dropzone";
import {
  Container,
  FileInput,
  Input,
  Title,
  CheckboxGroup,
  Main,
  Form,
  Button,
  Textarea,
} from "../../components";
import styles from "./styles.module.css";
import api from "../../api";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
import { useTags } from "../../utils";
import { fetcherUtils } from "../../utils/fetchUtils";
import { useImages } from "../../utils/use-images";
import { useHistory } from "react-router-dom";
import { FaUpload } from 'react-icons/fa';



const RecipeEdit = ({ onItemDelete }) => {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isUpdatingRecipe, setIsUpdatingRecipe] = useState(false); // Indicator for recipe creation

  const { mutate } = useSWRConfig();
  const { id } = useParams();
  const fetchRecipe = (key) => api.getRecipe({ recipe_id: key });

  const {
    data: recipeData,
    error: errorRecipeData,
    mutate: mutateRecipe,
 
  } = useSWR(id, fetchRecipe);

  console.log("load",isUpdatingRecipe)

  const { data: dataTags } = useSWR("/tags", () => api.getTags());
  const {
    value: valueTags,
    handleChange: handleChangeTags,
    setValue: setValueTags,
  } = useTags();

  const [recipeName, setRecipeName] = useState(recipeData?.name || "");
  const [recipeTower, setRecipeTower] = useState(recipeData?.tower || "");
  const [recipeTime, setRecipeTime] = useState(recipeData?.time || "");
  const [recipeText, setRecipeText] = useState(recipeData?.text || "");
  const [recipeFile, setRecipeFile] = useState(recipeData?.image || null);
  const [recipeFileWasManuallyChanged, setRecipeFileWasManuallyChanged] =
    useState(false);
  const { images, convertBase64, setImages } = useImages();

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const convertImageUrlToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const base64 = await convertBlobToBase64(blob);
      return base64;
    } catch (error) {
      console.error("Error converting image to base64:", error);
      return null;
    }
  };

  useEffect(() => {
    if (recipeData) {
      setRecipeName(recipeData?.name);
      setRecipeTower(recipeData?.tower);
      setRecipeTime(recipeData?.cooking_time);
      setRecipeText(recipeData?.text);
      setValueTags(
        dataTags?.map((tag) => ({
          ...tag,
          value: Boolean(
            recipeData?.tags?.find((item) => item?.id === tag?.id)
          ),
        }))
      );
      const updatedImages = recipeData?.images_recipe?.map((image) => ({
        errors: "",
        valid: true,
        name: image.name,
        size: image.size,
        type: image.type,
        imageUrl: image.imageUrl, // Используем URL изображения с сервера
        lastModified: image.lastModified,
      }));
      setImages(updatedImages);
    }
  }, [recipeData, dataTags, setImages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdatingRecipe(true); // Установка состояния загрузки в true
    let imagesData = [];
    try {
      const base64Images = await Promise.all(
        images.map(async (image) => {
          if (image.imageUrl.startsWith("http" || "https")) {
            // Преобразовать URL-изображение в base64
            const base64 = await convertImageUrlToBase64(image.imageUrl);
            return base64;
          } else {
            const response = await fetch(image.imageUrl);
            const blob = await response.blob();
            const base64_2 = await convertBlobToBase64(blob);
            // Изображение уже в формате base64
            return base64_2;
          }
        })
      );

      imagesData = images?.map((image, index) => ({
        ...image,
        imageUrl: base64Images[index],
      }));
      
    } catch (error) {
      console.error("Error uploading images:", error);
    }

    let data = {
      text: recipeText,
      name: recipeName,
      tower: recipeTower,
      tags: valueTags.filter((item) => item.value)?.map((item) => item.id),
      cooking_time: recipeTime,
      recipe_id: id,
    };

    if (recipeFile) {
      data.image = recipeFile;
    }

    if (imagesData.length > 0) {
      data.images_recipe = imagesData;
    }
    setIsUpdatingRecipe(true);
    try {
     
    const token = localStorage.getItem("token");
    mutateRecipe(
      fetcherUtils(`${process.env.REACT_APP_API_URL_SERVER}/api/recipes/${id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token}`,
        },
        body: JSON.stringify(data),
      })
    );
    setIsUpdatingRecipe(false);
  } catch (error) {
    console.error("Error creating recipe:", error);
    setIsUpdatingRecipe(false);
    // Handle recipe creation error
  } finally {
    setIsUpdatingRecipe(false); // Reset the indicator after the request is completed
  }
    setImages([]);
  };

  const handleFileChange = (acceptedFiles) => {
    setRecipeFile(acceptedFiles[0]);
    setRecipeFileWasManuallyChanged(true);
  };

  const handleAdditionalFilesChange = (acceptedFiles) => {
    const updatedImages = acceptedFiles?.map((file) => ({
      errors: "",
      valid: true,
      name: file.name,
      size: file.size,
      type: file.type,
      imageUrl: URL.createObjectURL(file),
      lastModified: file.lastModified,
    }));
    setImages((prevImages) => [...prevImages, ...updatedImages]);
  };

  const handleRemoveAdditionalFile = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleAdditionalFilesChange,
    maxSize: 10 * 1024 * 1024, // 10 МБ - ограничение размера файла
    multiple: true,
  });

  const checkIfDisabled = () => {
    // Реализуйте логику для проверки, должна ли кнопка быть отключена

  if (recipeName===''||recipeTower===''||recipeTime===''||recipeFile===null )
  {return true }else {return false}
  };

  if (errorRecipeData) {
    return <div>Ошибка при загрузке данных объекта</div>;
  }

  if (!recipeData) {
    return <div>Загрузка...</div>;
  }

  return (
    <Main>
      <Container>
        <MetaTags>
          <title>Редактирование объекта</title>
          <meta
            name="description"
            content="ППомощник по покупке/продаже - Редактирование объекта"
          />
          <meta property="og:title" content="Редактирование объекта" />
        </MetaTags>
        <Title title="Редактирование объекта" />
        <Form className={styles.form} onSubmit={handleSubmit}>
          <Input
            label="Название объекта"
            onChange={(e) => {
              const value = e.target.value;
              setRecipeName(value);
            }}
            value={recipeName}
          />
          <Input
            label="Название города"
            onChange={(e) => {
              const value = e.target.value;
              setRecipeTower(value);
            }}
            value={recipeTower}
          />
          <CheckboxGroup
            label="Теги"
            values={valueTags}
            className={styles.checkboxGroup}
            labelClassName={styles.checkboxGroupLabel}
            tagsClassName={styles.checkboxGroupTags}
            checkboxClassName={styles.checkboxGroupItem}
            handleChange={handleChangeTags}
          />

          <Input
            label="Стоимость объекта"
            onChange={(e) => {
              const value = e.target.value;
              setRecipeTime(value);
            }}
            value={recipeTime}
          />
          <Textarea
            label="Описание объекта"
            onChange={(e) => {
              const value = e.target.value;
              setRecipeText(value);
            }}
            value={recipeText}
          />

          <FileInput
            onChange={file => {
              setRecipeFileWasManuallyChanged(true)
              setRecipeFile(file)
            }}
            className={styles.fileInput}
            label="Загрузить фото"
            file={recipeData?.image || recipeFile}
          />
          <h3>Дополнительные фото:</h3>

          {/* DropZone */}

          <div className={styles.imagesContainer}>
            {images?.map((image, index) => (
              <div key={index} className={styles.imageContainer}>
                <img
                  src={image.imageUrl}
                  alt={`Изображение ${index + 1}`}
                  className={styles.image}
                />
                <button
                  type="button"
                  onClick={() => handleRemoveAdditionalFile(index)}
                  className={styles.removeButton}
                >
                  Удалить
                </button>
              </div>
            ))}
          </div>

          <div {...getRootProps()} className={`box {styles.dropzone}`}>
            <input {...getInputProps()} onChange={handleFileChange} />
            {isDragActive ? (
              <p><FaUpload/>Перетащите файл сюда ...</p>
            ) : (
              <p><FaUpload color="green"/> Перетащите сюда файлы или кликните, чтобы выбрать файлы</p>
            )}
          </div>

          <Button type="submit" disabled={isUpdatingRecipe}>
            {isUpdatingRecipe ? 'Сохранение объекта...' : 'Сохранить изменения'}
          </Button>
      
          <button
  className="button is-danger ml-2"
  onClick={() => setShowDeleteModal(true)}
>
  Удалить
</button>

<div className={`modal ${showDeleteModal ? 'is-active' : ''}`}>
  <div className="modal-background"></div>
  <div className="modal-content">
    <div className="box">
      <p>Вы уверены, что хотите удалить?</p>
      <div className="buttons">
        <button className="button is-danger" onClick={_ => {
              api.deleteRecipe({ recipe_id: id })
                .then(res => {
                  onItemDelete && onItemDelete()
                  history.push('/recipes')
                })
            }}>
          Удалить
        </button>
        <button className="button" onClick={() => setShowDeleteModal(false)}>
          Отмена
        </button>
      </div>
    </div>
  </div>
  <button
    className="modal-close is-large"
    aria-label="close"
    onClick={() => setShowDeleteModal(false)}
  ></button>
</div>
        </Form>
      </Container>
    </Main>
  );
};

export default RecipeEdit;
