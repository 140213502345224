import styles from './style.module.css'
import { Container, LinkComponent } from '../index'
import SocialNetworking from '../SocialNetworkingList'
import BottnMenuAppMobile from '../bulma-botton-menu'
import { isMobile } from "react-device-detect";

const Footer = ({ loggedIn }) => {
  return <>
   { isMobile ? (<section className='hero is-large mt-6'><BottnMenuAppMobile loggedIn={loggedIn} /></section>) : 
   <footer className={styles.footer}>
    
   <Container className={styles.footer__container}>
   
     <LinkComponent href='/' title='Помощник по покупке/продаже' className={styles.footer__brand} />
     <SocialNetworking />
   </Container>
</footer>}
  
  
  </>
}

export default Footer
