import { Container, Input, Title, Main, Form, Button } from '../../components'
import styles from './styles.module.css'
import { useFormWithValidation } from '../../utils'
import { AuthContext } from '../../contexts'
import { Redirect } from 'react-router-dom'
import { useContext, useState } from 'react'
import MetaTags from 'react-meta-tags'
import React from 'react'
import ForgotForm from '../../components/ForgotForm'


const SignIn = ({ onSignIn }) => {
  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation()
  const authContext = useContext(AuthContext)


  function useToggleSignIn(initialValue = false) {
    const [value, setValue] = React.useState(initialValue);
    const toggle = React.useCallback(() => {
      setValue(v => !v);
    }, []);
    return [value, toggle];
  }
 
  const [isSignin, toggleIsOnSignin] = useToggleSignIn();
  const [isOff, setIsOff] = useState(true);

  return <Main>
    {authContext && <Redirect to='/recipes' />}
    <Container>
      <MetaTags>
        <title>Войти на сайт</title>
        <meta name="description" content="Интернет магазин - Войти на сайт" />
        <meta property="og:title" content="Войти на сайт" />
      </MetaTags>
      <Title title='Войти на сайт'/>
      
     
 
{isSignin ? 
          <ForgotForm />
          :
    <Form
      className={styles.form}
      onSubmit={e => {
        e.preventDefault()
        onSignIn(values)
      }}
      >
      <Input
        required
        label='Электронная почта'
        name='email'
        onChange={handleChange}
      />
      <Input
        required
        label='Пароль'
        type='password'
        name='password'
        onChange={handleChange}
      />
      <Button
        modifier='style_dark-blue'
        disabled={!isValid}
        type='submit'
        className={styles.button}
      >
        Войти
      </Button>   
      
    </Form>}
    <a onClick={toggleIsOnSignin}>
        <span className='level-center' onClick={() => setIsOff(!isOff)}>{ isOff ? 'Сбросить пароль?' : 'Войти на сайт' }</span>
      </a>

  </Container>
</Main>
}

export default SignIn
