import React, { useState, useEffect } from "react";
import { FaTelegramPlane } from 'react-icons/fa';




const SocialNetworking = (props) => {

 
    return (
        <div className="buttons mt-3 ml-2">          
            <a className="level-item  has-text-left-mobile mr-2" href="https://t.me/domosearch"><FaTelegramPlane /></a>
        </div>
    )
}

export default SocialNetworking;
