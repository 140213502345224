import cn from 'classnames'
import styles from './styles.module.css'
import { useState } from 'react'
import CheckboxButtonBox  from '../checkboxButtonBox'
// import { Checkbox } from '../../index'



const CheckboxGroupButtomCustom = ({
  onUpdated,
  label,
  values = [],
  handleChange,
  className,
  labelClassName,
  tagsClassName,
  checkboxClassName
}) => {
  return <div className={cn(styles.checkboxGroup, className)}>
    {label && <div className={cn(styles.label, labelClassName)}>
      {label}
    </div>}
    <div className={cn(styles.checkboxGroupItems, tagsClassName)}>
      {values?.map(item => {
        return <div className='   ml-2 mr-2'  key={item.id}>
          
<CheckboxButtonBox
          
          id={item.id}
          value={item.value}
          name={item.name}
          color={item.color}
          onChange={handleChange}
          className={`box ${checkboxClassName}`}
        />

        </div>
      })}
    </div>
  </div>
}


export default CheckboxGroupButtomCustom;