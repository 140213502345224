import React, { Component } from 'react';
import styles from './style.module.css'



class MetkaCard extends Component {
  render() {
   
    return (
      <div className={styles.metkatop}>
      {/* TOP       */}
      {/* <img src={imgTopMetka}  alt="TOP" />    */}
      </div>
    )
  }
}

export default MetkaCard;