const Modal1 = ({ handleClose, show, children }) => {
    const showHideClassName = show ? '' : 'modal';
  
    return (
      <div className={showHideClassName}>       
        <section className="hero modal-content">  
          {children}
          <button className='button is-warning is-rounded'
            onClick={handleClose}
          >
            Закрыть
          </button>
        </section>
      </div>
    );
  };
export default Modal1;
