import styles from './style.module.css'
import { Nav, AccountMenu } from '../index.js'
import Container from '../container'
import BulmaMenu from '../bulma-menu'


const Header = ({ loggedIn, onSignOut, orders }) => {
  return <header
    className={styles.header}
  >
    <Container>
    <BulmaMenu loggedIn={loggedIn} orders={orders} onSignOut={onSignOut}/> 

      <div className={styles.headerContent}>
        {/* admin */}
        {/* <a  className={styles.header}  href="http://localhost:8000/admin/">admin</a> */}
        {/* end admin  */}
      </div>
    </Container>
  </header>
}

export default Header
