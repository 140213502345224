import Slider from "react-slick";
import Zoom from 'react-reveal/Zoom';
import Modal from "react-modal";
import React, { useState, useEffect } from 'react'
import { FcNext } from 'react-icons/fc';
import { GrGallery } from 'react-icons/gr';

const GalleryWidgetDZ = ( {images_list} ) => {
  const settingsMobile = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true, // enable center mode
    centerPadding: '15px' // set center padding

  };

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  
  function closeModal() {
    setIsOpen(false);
  }


return <section>
   
       
  <div className="column is-one-third" style={{width: '50px', position: "absolute", top: "70px", left: '30px'}}>
          <div className="button" onClick={openModal} >
          <GrGallery /><FcNext />
          </div>
        </div>

 
<Modal  
  isOpen={modalIsOpen}
  ariaHideApp={false}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={'hero has-background-link'}
        contentLabel="Example Modal">

 
  <button className='button is-danger' onClick={closeModal}>close</button>
     
    <Zoom>
    <figure className="image  " >
        <Slider {...settingsMobile} >
          {images_list.map((imgSrc, index) => (<img  src={imgSrc} key={index} />))}
        </Slider>
            </figure>
    </Zoom>
    </Modal>

</section>
   
  }
  
  export default GalleryWidgetDZ;
