import { Card, Title, Pagination, CardList, Container, Main, CheckboxGroup  } from '../../../components'
import styles from './styles.module.css'
import { useRecipes } from '../../../utils/index.js'
import { useEffect } from 'react'
import api from '../../../api'
import MetaTags from 'react-meta-tags'

import { useState } from 'react'
import 'bulma/css/bulma.min.css';
// corousel
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "react-device-detect";
import CardGallary from '../../../components/card-gallary'

import Modal1 from '../../../components/modal-1'

const CategoryServicesPage = ({ updateOrders }) => {
 

  const {
    recipes,
    setRecipes,
    recipesCount,
    setRecipesCount,
    recipesPage,
    setRecipesPage,
    tagsValue,
    
    setTagsValue,
    handleTagsChange,
    handleLike,
    handleAddToCart
  } = useRecipes()
 

  const getRecipes = ({ page = 1, tags }) => {
    api
      .getRecipes({ page, tags })
      .then(res => {
        const { results, count } = res
        console.log(res)
        setRecipes(results)
        setRecipesCount(count)
      })
  }

  useEffect(_ => {
    getRecipes({ page: recipesPage, tags: [{color: "#f0f71b", id: 3, name: "Услуги", slug: "gostinitsy-0", value: true}]})
  }, [recipesPage])

  useEffect(_ => {
    api.getTags()
      .then(tags => {
        setTagsValue(tags.map(tag => ({ ...tag, value: true })))
      })
  }, [])
  
  return <Main>
    <Container>
      <MetaTags>
        <title>Услуги</title>
        <meta name="description" content="Помощник по покупке/продаже - Услуги" />
        <meta property="og:title" content="Услуги" />
      </MetaTags>

      <CardList>
        {recipes.map(card => <Card
          {...card}
          key={card.id}
          updateOrders={updateOrders}
          handleLike={handleLike}
          handleAddToCart={handleAddToCart}
        />)}
      </CardList>
      <Pagination
        count={recipesCount}
        limit={6}
        page={recipesPage}
        onPageChange={page => setRecipesPage(page)}
      />
    </Container>
  </Main>
}


export default CategoryServicesPage;