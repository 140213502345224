import 'bulma/css/bulma.min.css';
import Logo from '../logo'
import {Nav, AccountMenu } from '../index.js'
import React from 'react'

import SocialNetworking from '../SocialNetworkingList';
import BulmaMenuMobile from '../bulma-menu-mobile';

import { isMobile } from "react-device-detect";

const BulmaMenu = ({loggedIn, onSignOut, orders}) => {
const [isActive, setisActive] = React.useState(false);

    return (
      <>

   
{!isMobile ? (<nav className="navbar" role="navigation" aria-label="main navigation">
              <div className="navbar-brand">
                
                     <a href="/recipes">
                     <Logo />   </a>       
               

                <a 
                onClick={() => {
                  setisActive(!isActive);
                }}
                
                 role="button"className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
               

              </div>
            
              <div 
              id="navbarBasicExample" 
              className={`navbar-menu ${isActive ? "is-active" : ""}`}
              >                     

                <div className="navbar-start">
                <Nav loggedIn={loggedIn} orders={orders} />
<nav className="has-text-left-mobile">
<div className='level-left '>
<SocialNetworking />
</div>
</nav>
                </div>
            
                <div className="navbar-end">
                  <div className="navbar-item">
                    <div className="buttons">                   
                      <AccountMenu onSignOut={onSignOut} /> 
                    </div>
                  </div>
                </div>
              </div>
  </nav>) : 

            <BulmaMenuMobile onSignOut={onSignOut} />}
      </>
    )
}

export default BulmaMenu;
