import styles from './style.module.css'
import { LinkComponent, Icons, Button, TagsContainer } from '../index'
import { useState, useContext } from 'react'
import { AuthContext } from '../../contexts'
import MetkaCard from '../metka-top'
//import 'bulma/css/bulma.min.css';

const CardGallary = ({
  name = 'Без названия',
  id,
  image,
  is_top
  
}) => {
  const authContext = useContext(AuthContext)
  return <div className={styles.card}>
    {is_top ? <>< MetkaCard /></> : <></>}
  <LinkComponent
    className={styles.card__title}
    href={`/recipes/${id}`}
    title={<div className={styles.card__image} style={{ backgroundImage: `url(${ image })` }} />}
  />

  </div>
}

export default CardGallary;